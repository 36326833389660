<template>
  <div>
    <h1 class="page-title">
      {{ title }}
    </h1>
    <Widget class="shadow-xl">
      <v-client-table
        :data="records"
        :columns="columns"
        :options="options"
        ref="table"
      >
        <div slot="afterFilter" class="flex space-x-2">
          <slot name="afterFilter">
            <b-button
              variant="success"
              v-b-modal.modal-form
              v-if="handlePermissions('new')"
              @click="setSelectedRow(null)"
            >
              <i
                class="glyphicon glyphicon-plus transform -translate-y-1.5"
              ></i>
              Nuevo
            </b-button>
            <b-button variant="success" @click="handleLoadData">
              <i
                class="glyphicon glyphicon-refresh transform -translate-y-1"
              ></i>
            </b-button>
          </slot>
        </div>
        <div
          slot="actions"
          slot-scope="{ row }"
          class="flex items-center space-x-2"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            @click="setSelectedRow(row)"
            v-b-modal.modal-form
            v-if="handlePermissions('edit', row)"
          >
            <i class="glyphicon glyphicon-pencil transform -translate-y-1"></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="setSelectedRow(row)"
            v-b-modal.modal-delete-record
            v-if="handlePermissions('delete')"
          >
            <i class="glyphicon glyphicon-trash transform -translate-y-1"></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="setSelectedRow(row)"
            v-b-modal.modal-documents-form
            v-if="handlePermissions('documents')"
          >
            <i
              class="glyphicon glyphicon-folder-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="setSelectedRow(row)"
            v-b-modal.modal-record-details
            v-if="handlePermissions('details')"
          >
            <i
              class="glyphicon glyphicon-eye-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="handleLoan(row)"
            v-if="handlePermissions('lend')"
          >
            <i class="fa fa-money-bill-wave"></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="handleTraceprocesslawyer(row)"
            v-if="handlePermissions('traceProcess')"
          >
            <i class="fas fa-layer-group"></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="setSelectedRow(row)"
            v-b-modal.modal-process-lawyer-form
            v-if="handlePermissions('process')"
          >
            <i class="fas fa-balance-scale"></i>
          </b-button>
        </div>
      </v-client-table>
    </Widget>
    <delete-record-modal
      :isLoading="isLoadingDeletion"
      @delete="erase"
      :row="selectedRow"
    />
    <base-modal-documents-form title="Documentos" :row="selectedRow">
      <template v-slot:formfields>
        <slot name="documentsformfields"></slot>
      </template>
    </base-modal-documents-form>
    <base-record-details :row="selectedRow">
      <template v-slot:recorddetails>
        <slot name="recorddetails" v-bind="selectedRow"></slot>
      </template>
    </base-record-details>
  </div>
</template>

<script>
const qs = require("qs");
import { deserialize } from "jsonapi-fractal";
import DeleteRecordModal from "./DeleteRecordModal";
import BaseModalDocumentsForm from "./BaseModalDocumentsForm";
import BaseRecordDetails from "./BaseRecordDetails";
import { moduleActions } from "@/mixins/actions.js";
export default {
  props: {
    moduleOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    title: {
      type: String,
      default: ""
    },
    loadOnInit: {
      type: Boolean,
      default: true
    }
  },

  mixins: [moduleActions],

  computed: {
    columns() {
      let cols = this.moduleOptions.columns.map(col => {
        return col.prop;
      });

      cols.push("actions");
      return cols;
    },

    headings() {
      let heads = {};

      this.moduleOptions.columns.forEach(col => {
        heads[col.prop] = col.header;
      });

      heads.actions = "";
      return heads;
    }
  },

  components: {
    DeleteRecordModal,
    BaseModalDocumentsForm,
    BaseRecordDetails
  },

  data() {
    return {
      records: [],
      options: {},
      isLoadingDeletion: false,
      selectedRow: null,
      permissions: []
    };
  },

  methods: {
    async handleLoadData() {
      const me = this;
      me.records = [];
      me.$refs.table.setLoadingState(true);
      let getparams =
        Object.prototype.hasOwnProperty.call(
          me.moduleOptions.repository,
          "params"
        ) && me.moduleOptions.repository.params.get
          ? "?" + qs.stringify(me.moduleOptions.repository.params.get)
          : "";

      let response = await me.$parent[me.moduleOptions.repository.name].get(
        getparams
      );

      me.records = deserialize(response.data);
      me.$refs.table.setLoadingState(false);
    },

    handleLoan(row) {
      this.$router.push({
        name: "Loans",
        params: {
          clientId: row.id
        }
      });
    },

    handleTraceprocesslawyer(row) {
      this.$router.push({
        name: "Tracesprocesslawyers",
        params: {
          processlawyerId: row.id
        }
      });
    },

    handlePermissions(permission, row) {
      if (row && Object.prototype.hasOwnProperty.call(row, "is_editable")) {
        return this.permissions.includes(permission) && row.is_editable === 1;
      }
      return this.permissions.includes(permission);
    }
  },

  mounted() {
    const me = this;

    me.options = {
      ...me.options,
      ...{
        headings: me.headings,
        sortable: me.moduleOptions.sortable ? me.moduleOptions.sortable : [],
        templates: me.moduleOptions.templates ? me.moduleOptions.templates : {}
      }
    };

    if (me.loadOnInit) {
      me.handleLoadData();
    }

    if (Object.prototype.hasOwnProperty.call(me.$route.meta, "permissions")) {
      me.permissions = me.$route.meta.permissions;
    }
  }
};
</script>

<style lang="scss" scoped></style>
