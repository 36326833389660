import { render, staticRenderFns } from "./BaseModule.vue?vue&type=template&id=712c10c4&scoped=true&"
import script from "./BaseModule.vue?vue&type=script&lang=js&"
export * from "./BaseModule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "712c10c4",
  null
  
)

export default component.exports